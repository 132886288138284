import React from 'react'
import Wrapper from '../styles/Wrapper'
import { media } from '../styles/minWidth'
import Header from '../components/Header'
import Prices from '../components/Prices'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, excerpt, html } = markdownRemark

  return (
    <Layout title={frontmatter.title} content={excerpt}>
      <Header />
      <Wrapper contentWidth={840}>
        <h1>{frontmatter.title}</h1>

        {frontmatter.path === '/tarieven-vergoedingen' && <Prices />}

        <div
          css={{
            '> :last-child': {
              margin: 0,
            },
            '.gatsby-resp-image-wrapper':
              frontmatter.path === '/over-friska'
                ? {
                    width: 200,
                    [media.medium]: {
                      float: 'right',
                      margin: '0 0 20px 30px !important',
                    },
                  }
                : {
                  marginLeft: '0 !important',
                  [media.medium]: {
                    float: 'left',
                    marginRight: '20px !important',
                    width: 250,
                  }
                },
          }}
          dangerouslySetInnerHTML={{
            __html: html
              .replace(/<table>/g, '<div style="overflow-x: scroll;"><table>')
              .replace(/<\/table>/g, '</table></div>'),
          }}
        />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query PageByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      excerpt
      html
      frontmatter {
        path
        title
      }
    }
  }
`
